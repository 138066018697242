import React from "react"
import SEO from "../components/seo"
import Link from "gatsby-plugin-transition-link/AniLink"


const NotFoundPage = () => (
    <div className="notFound">
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>This page seems to be missing or has never been created!</p>
      <Link fade to="/" duration={.66}>Back to home</Link>
    </div>
)

export default NotFoundPage
